import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ErrorPage = () => (
  <Layout>
    <SEO title="500: Server Error" />
    <h1>Oops!</h1>
    <p>This is probably our fault, please come back later!</p>
  </Layout>
)

export default ErrorPage
